<template>
  <div class="content-dashboard-report-component-all">
    <div class="content-dashboard-report-mxn">
      <div class="content-dashboard-report-entry">
        <p class="txt-dashboard-report">Ingresos</p>
        <div class="content-dashboard-report-component-icon-price">
          <div class="content-icon-report-entry">
            <v-icon size="20" color="var(--primary-color-text-white)">
              mdi-currency-usd
            </v-icon>
          </div>
          <p class="txt-price-dashboard-report">
            $ {{ formatMoneyGlobal(dIncomeBalance) }} MXN
          </p>
        </div>
      </div>

      <div class="content-dashboard-report-discharge">
        <p class="txt-dashboard-report">Egresos</p>
        <div class="content-dashboard-report-component-icon-price">
          <div class="content-icon-report-discharge">
            <v-icon size="20" color="var(--primary-color-text-white)">
              mdi-currency-usd
            </v-icon>
          </div>
          <p class="txt-price-dashboard-report">
            ${{ formatMoneyGlobal(dExpensesBalance) }} MXN
          </p>
        </div>
      </div>
    </div>

    <div class="content-dashboard-report-usd mt-5">
      <div class="content-dashboard-report-entry">
        <p class="txt-dashboard-report">Ingresos</p>
        <div class="content-dashboard-report-component-icon-price">
          <div class="content-icon-report-entry">
            <v-icon size="20" color="var(--primary-color-text-white)">
              mdi-currency-usd
            </v-icon>
          </div>
          <p class="txt-price-dashboard-report">
            $ {{ formatMoneyGlobal(dIncomeBalanceUSD) }} USD
          </p>
        </div>
      </div>

      <div class="content-dashboard-report-discharge">
        <p class="txt-dashboard-report">Egresos</p>
        <div class="content-dashboard-report-component-icon-price">
          <div class="content-icon-report-discharge">
            <v-icon size="20" color="var(--primary-color-text-white)">
              mdi-currency-usd
            </v-icon>
          </div>
          <p class="txt-price-dashboard-report">
            ${{ formatMoneyGlobal(dExpensesBalanceUSD) }} USD
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dIncomeBalance: Number,
    dExpensesBalance: Number,
    dIncomeBalanceUSD: Number,
    dExpensesBalanceUSD: Number,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.content-dashboard-report-component-all {
}
.content-dashboard-report-mxn,
.content-dashboard-report-usd {
  display: flex;
}

.content-dashboard-report-entry,
.content-dashboard-report-discharge {
  width: 50%;
  height: 65px;
  margin-right: 10px;
  padding: 10px 10px 10px 10px;
  background-color: var(--primary-color-background-table) !important;
  border: 1px solid var(--primary-color-border-input);
  border-radius: 10px;
  display: block;
  align-items: center;
  text-align: center;
}

.txt-dashboard-report {
  font-family: "pop-Regular";
  font-size: 12px;
  color: var(--primary-color-text);
  margin-bottom: 0px;
}

.content-dashboard-report-component-icon-price {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-icon-report-entry {
  background-color: var(--primary-color-background-icon-status-green);
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  border-radius: 100%;
}

.content-icon-report-discharge {
  background-color: var(--primary-color-background-icon-status-red);
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  border-radius: 100%;
}

.txt-price-dashboard-report {
  margin-bottom: 0px;
  margin-left: 10px;
  font-family: "pop-SemiBold";
  color: var(--primary-color-text);
}

/*Plantilla media query css responsive*/

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-dashboard-report-mxn,
  .content-dashboard-report-usd {
    display: block;
  }

  .content-dashboard-report-entry,
  .content-dashboard-report-discharge {
    width: 100%;
    height: 65px;
    margin-top: 15px;
    margin-right: 10px;
    padding: 10px 10px 10px 10px;
    background-color: var(--primary-color-background-table) !important;
    border: 1px solid var(--primary-color-border-input);
    border-radius: 10px;
    display: block;
    align-items: center;
    text-align: center;
  }

  .txt-dashboard-report {
    font-family: "pop-Regular";
    font-size: 12px;
    color: var(--primary-color-text);
    margin-bottom: 0px;
  }

  .content-dashboard-report-component-icon-price {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-icon-report-entry {
    background-color: var(--primary-color-background-icon-status-green);
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    border-radius: 100%;
  }

  .content-icon-report-discharge {
    background-color: var(--primary-color-background-icon-status-red);
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    border-radius: 100%;
  }

  .txt-price-dashboard-report {
    margin-bottom: 0px;
    margin-left: 10px;
    font-family: "pop-SemiBold";
    color: var(--primary-color-text);
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
